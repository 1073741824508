// @ts-strict-ignore
// Copyright (C) 2022 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap, shareReplay, switchMap, take } from 'rxjs/operators';

import { Client } from '../client/client.model';
import { ClientService } from '../client/client.service';
import { AuthService } from '../core/auth/auth.service';
import { EntityService } from '../core/entity/entity.service';
import { AlertService } from '../shared/alert.service';
import { User, UserRole, UserInfoWithHMAC } from './user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService extends EntityService<User> {
  private userInfoWithHMAC: UserInfoWithHMAC = null;

  constructor(
    protected authService: AuthService,
    protected clientService: ClientService,
    protected alertService: AlertService,
    protected httpClient: HttpClient,
  ) {
    super(alertService, httpClient);
  }

  getAllByClient$(clientId: number): Observable<User[]> {
    return super.getAll$({ client_id: clientId.toString() });
  }

  getAllByRole$(role: keyof typeof UserRole): Observable<User[]> {
    return super.getAll$({ role });
  }

  // 20230327: Unused method. The current logic is also deprecated. Endpoint has changed.
  // updateClientRoleForUser$(clientId: number, userId: number, newRole: keyof typeof ClientRole): Observable<unknown> {
  //   const url = this.getWebApiEndpoint('update-client-role-for-user');
  //   const body = { user_id: userId, client_id: clientId, new_role: newRole };
  //   const req$ = this.httpClient.patch(url, body);
  //   return this.handleUpdateOrDeleteResponse(req$, {
  //     log: `Update client(${clientId}) role(${newRole}) for ${this.singularIdentifier()}(${userId}) failed:`,
  //     nice: `Failed to update client role for ${this.singularIdentifier()}`,
  //   });
  // }

  termsOfUseAccepted$(userId?: number): Observable<boolean> {
    return this.getUserId$(userId).pipe(
      mergeMap((id: number) => this.getOne$(id)),
      map((user: User) => user.termsOfUseAccepted !== null),
      shareReplay(1),
    );
  }

  updateTermsOfUseAgreement$(userId?: number): Observable<unknown> {
    return this.getUserId$(userId).pipe(
      mergeMap((uid: number) => {
        const url = this.getWebApiEndpoint('terms-of-use', uid);
        const req$ = this.httpClient.put(url, {});
        return this.handleUpdateOrDeleteResponse(req$, {
          log: `Update terms of use agreement for ${this.singularIdentifier()}(${uid}) failed:`,
          nice: `Failed to update terms of use agreement for ${this.singularIdentifier()}`,
        });
      }),
    );
  }

  hasRole$(roles: (keyof typeof UserRole)[], userId?: number): Observable<boolean> {
    return this.getUserId$(userId).pipe(
      mergeMap((id: number) => this.getOne$(id)),
      take(1),
      // Do they have at least one of the required roles?
      map((user: User) => user.roles?.some(role => roles.some(r => r === role))),
    );
  }

  canAccessEducation$(): Observable<boolean> {
    return this.getUserId$().pipe(
      mergeMap((id: number) =>
        this.clientService.getAllByUser$(id).pipe(
          // User can access education if any of the clients have enabled education.
          map((clients: Client[]) => clients.some(client => client.active && client.enabledEducation)),
        ),
      ),
    );
  }

  /**
   * Gets the Product Fruits user Hmac object from the stored userInfoWithHMAC and return it if it exists
   * and hasn't exipred. If stored HMAC exists but has expired it will call the backend api to generate
   * a new HMAC and return it.
   *
   * @returns UserInfoWithHMAC object that contains the hmac hash, userId and the exipration time
   */
  getProductFruitsUserHmac$(): Observable<UserInfoWithHMAC> {
    // check for existing hmac
    if (this.userInfoWithHMAC) {
      // check if the expiration date on the hmac has expired
      const currentDate = new Date();
      const expirationDate = new Date(this.userInfoWithHMAC.hmac.expiration);
      if (expirationDate.getTime() > currentDate.getTime()) {
        // if expiration time is great than current time this hmac is still valid
        return of(this.userInfoWithHMAC);
      }
    }
    // fetch the hmac from the api and store it for reuse
    return this.fetchProductFruitsUserHmac$().pipe(
      map(userInfoWithHMAC => {
        // store this current userInfoWithHMAC
        this.userInfoWithHMAC = userInfoWithHMAC;
        return userInfoWithHMAC;
      }),
    );
  }

  /**
   * Helper method fetches the product fruits user HMAC object from the backend api
   * The UserInfoWithHMAC object is formatted specifically that can be use by the products fruit component
   *
   * @returns UserInfoWithHMAC object that contains the hmac hash, userId and the exipration time
   */
  fetchProductFruitsUserHmac$(): Observable<UserInfoWithHMAC> {
    return this.getUserId$().pipe(
      mergeMap((id: number) => {
        const url = this.getWebApiEndpoint('pf-hmac', id);
        const req$ = this.httpClient.get<UserInfoWithHMAC>(url);
        return this.handleGetOrCreateResponse(req$, {
          log: `Get product fruits user hmac from ${this.singularIdentifier()} failed:`,
          nice: `Get product fruits user hmac from ${this.singularIdentifier()}`,
        });
      }),
    );
  }

  /**
   * Helper method to get the id of the logged in user.
   *
   * @param userId an optional user id to return if provided
   * @returns the logged in user's id or the user id provided
   */
  getUserId$(userId?: number): Observable<number> {
    if (userId) {
      return of(userId);
    } else {
      return this.authService.userProfile$.pipe(
        take(1),
        map(profile => profile.userId),
      );
    }
  }

  /**
   * Helper method to get the User of the logged in user.
   *
   * @returns the logged in user's User or the User of the user id
   * provided
   */
  getUser$(): Observable<User> {
    return this.getUserId$().pipe(switchMap((id: number) => this.getOne$(id)));
  }

  changeUserRole(user: Partial<User>, role: keyof typeof UserRole, selected: boolean): void {
    if (!Array.isArray(user.roles)) {
      user.roles = [];
    }

    // Add the role if selected.
    if (selected && !user.roles.includes(role)) {
      user.roles.push(role);
    }

    // Remove the role if not selected.
    if (!selected) {
      user.roles = user.roles.filter(r => r !== role);
    }
  }

  pluralIdentifier(): string {
    return 'users';
  }

  singularIdentifier(): string {
    return 'user';
  }
}
